<template>
  <div class="service-wrapper service-white">
    <v-row class="row--25">
      <!-- Start Single Service  -->
      <v-col
        xl="3"
        lg="3"
        md="3"
        sm="6"
        cols="12"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="service service__style--1">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h4 class="heading-title">
              {{ service.title }}
            </h4>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </v-col>
      <!-- End Single Service  -->
    </v-row>
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "monitor",
            title: "IT 컨설팅",
            desc: `비즈니스 효율성을 극대화하는 맞춤형 전략`,
          },
          {
            icon: "code",
            title: "ML & AIgorithm",
            desc: `첨단 머신러닝과 알고리즘으로 데이터 분석 혁신`,
          },
          {
            icon: "database",
            title: "Big Data Platform",
            desc: `복잡한 대규모 데이터를 효과적으로 관리`,
          },
                    {
            icon: "users",
            title: "SI & SM",
            desc: `통합 시스템 구축과 지속적인 관리로 안정성 보장`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .single-service.service__style--3 {
    text-align: center;
  }
</style>
